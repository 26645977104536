import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { LandingGuard } from './landing.guard';
import { LoginGuard } from './login.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingPageModule)
  },
  {
    path: 'startbusiness',
    loadChildren: () => import('./startbusiness/startbusiness.module').then(m => m.StartbusinessPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'verification',
    loadChildren: () => import('./verification/verification.module').then(m => m.VerificationPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'transactions',
    loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsPageModule)
  },
  {
    path: 'transactions-detail',
    loadChildren: () => import('./transactions-detail/transactions-detail.module').then(m => m.TransactionsDetailPageModule)
  },
  {
    path: 'bankstatement',
    loadChildren: () => import('./bankstatement/bankstatement.module').then(m => m.BankstatementPageModule)
  },
  {
    path: 'invoice',
    loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoicePageModule)
  },
  {
    path: 'invoice-upload',
    loadChildren: () => import('./invoice-upload/invoice-upload.module').then(m => m.InvoiceUploadPageModule)
  },
  {
    path: 'invoice-new',
    loadChildren: () => import('./invoice-new/invoice-new.module').then(m => m.InvoiceNewPageModule)
  },
  {
    path: 'banklist',
    loadChildren: () => import('./banklist/banklist.module').then(m => m.BanklistPageModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('./billing/billing.module').then(m => m.BillingPageModule)
  },
  {
    path: 'mycompany',
    loadChildren: () => import('./mycompany/mycompany.module').then(m => m.MycompanyPageModule)
  },
  {
    path: 'mycompany-start',
    loadChildren: () => import('./mycompany-start/mycompany-start.module').then(m => m.MycompanyStartPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'request-create',
    loadChildren: () => import('./request-create/request-create.module').then(m => m.RequestCreatePageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'company-secretarial',
    loadChildren: () => import('./company-secretarial/company-secretarial.module').then(m => m.CompanySecretarialPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'business-address',
    loadChildren: () => import('./business-address/business-address.module').then(m => m.BusinessAddressPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'issue-share',
    loadChildren: () => import('./issue-share/issue-share.module').then(m => m.IssueSharePageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'issue-share-request',
    loadChildren: () => import('./issue-share-request/issue-share-request.module').then(m => m.IssueShareRequestPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'selector',
    loadChildren: () => import('./selector/selector.module').then(m => m.SelectorPageModule)
  },
  {
    path: 'payroll',
    loadChildren: () => import('./payroll/payroll.module').then(m => m.PayrollPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'tab4',
    loadChildren: () => import('./tab4/tab4.module').then(m => m.Tab4PageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'start',
    loadChildren: () => import('./start/start.module').then(m => m.StartPageModule)
  },
  {
    path: 'start-register',
    loadChildren: () => import('./start-register/start-register.module').then(m => m.StartRegisterPageModule)
  },
  {
    path: 'start-login',
    loadChildren: () => import('./start-login/start-login.module').then(m => m.StartLoginPageModule)
  },
  {
    path: 'transfer',
    loadChildren: () => import('./transfer/transfer.module').then(m => m.TransferPageModule)
  },
  {
    path: 'document',
    loadChildren: () => import('./document/document.module').then(m => m.DocumentPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'all-tasks',
    loadChildren: () => import('./all-tasks/all-tasks.module').then(m => m.AllTasksPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'all-requests',
    loadChildren: () => import('./all-requests/all-requests.module').then(m => m.AllRequestsPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'all-documents',
    loadChildren: () => import('./all-documents/all-documents.module').then(m => m.AllDocumentsPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule),
    canLoad: [LandingGuard]
  },
  {
    path: 'all-files',
    loadChildren: () => import('./all-files/all-files.module').then(m => m.AllFilesPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'kyc',
    loadChildren: () => import('./kyc/kyc.module').then(m => m.KycPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'xero-access',
    loadChildren: () => import('./xero-access/xero-access.module').then(m => m.XeroAccessPageModule)
  },
  {
    path: 'all-reports',
    loadChildren: () => import('./all-reports/all-reports.module').then(m => m.AllReportsPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'all-transactions',
    loadChildren: () => import('./all-transactions/all-transactions.module').then(m => m.AllTransactionsPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'all-outstandingtasks',
    loadChildren: () => import('./all-outstandingtasks/all-outstandingtasks.module').then(m => m.AllOutstandingtasksPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'new-transaction',
    loadChildren: () => import('./new-transaction/new-transaction.module').then(m => m.NewTransactionPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'bank-selection',
    loadChildren: () => import('./bank-selection/bank-selection.module').then(m => m.BankSelectionPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'kyc-alert',
    loadChildren: () => import('./kyc-alert/kyc-alert.module').then(m => m.KycAlertPageModule)
  },
  {
    path: 'webcam',
    loadChildren: () => import('./webcam/webcam.module').then(m => m.WebcamPageModule)
  },
  {
    path: 'tnc',
    loadChildren: () => import('./tnc/tnc.module').then(m => m.TncPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'update',
    loadChildren: () => import('./update/update.module').then( m => m.UpdatePageModule)
  },
  {
    path: 'tab4-applications',
    loadChildren: () => import('./tab4-applications/tab4-applications.module').then( m => m.Tab4ApplicationsPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'resetpassword/:uid',
    loadChildren: () => import('./resetpassword/resetpassword.module').then( m => m.ResetpasswordPageModule)

  },
  {
    path: 'start-business-selection',
    loadChildren: () => import('./start-business-selection/start-business-selection.module').then( m => m.StartBusinessSelectionPageModule)
  },
  {
    path: 'enterprise-partnership',
    loadChildren: () => import('./enterprise-partnership/enterprise-partnership.module').then( m => m.EnterprisePartnershipPageModule)
  },
  {
    path: 'limited-liability-partnership',
    loadChildren: () => import('./limited-liability-partnership/limited-liability-partnership.module').then( m => m.LimitedLiabilityPartnershipPageModule)
  },
  {
    path: 'company-start-type',
    loadChildren: () => import('./company-start-type/company-start-type.module').then( m => m.CompanyStartTypePageModule)
  },
  // <----- FusionETA - JY (12 July 2023) - (Pop out video): adding path of video-alert - Start ----->
  {
    path: 'video-alert',
    loadChildren: () => import('./video-alert/video-alert.module').then( m => m.VideoAlertPageModule)
  },
  // <----- FusionETA - JY (12 July 2023) - (Pop out video): adding path of video-alert - End ----->
  // <----- FusionETA - Alvin (19 Sept 2023) - (loan-calculator): adding path  - Start ----->
  {
    path: 'loan-calculator',
    loadChildren: () => import('./loan-calculator/loan-calculator.module').then( m => m.LoanCalculatorPageModule)
  },
  // <----- FusionETA - Alvin (19 Sept 2023) - (loan-calculator): adding path  - End ----->

  // <----- FusionETA - JY (27 September 2023) - Progress bar: adding path of progress bar - Start ----->
  {
    path: 'progress-bar',
    loadChildren: () => import('./progress-bar/progress-bar.module').then( m => m.ProgressBarPageModule)
  },
  {
    path: 'open-mobile',
    loadChildren: () => import('./open-mobile/open-mobile.module').then( m => m.OpenMobilePageModule)
  },
  {
    path: 'invoice-company',
    loadChildren: () => import('./invoice-company/invoice-company.module').then( m => m.InvoiceCompanyPageModule)    
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'folder',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'loan-list',
    loadChildren: () => import('./loan-list/loan-list.module').then( m => m.LoanListPageModule)
  },
  {
    path: 'credit-note',
    loadChildren: () => import('./credit-note/credit-note.module').then( m => m.CreditNotePageModule)
  },
  {
    path: 'team-management',
    loadChildren: () => import('./team-management/team-management.module').then( m => m.TeamManagementPageModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./team/team.module').then( m => m.TeamPageModule)
  },
  {
    path: 'invitation-link',
    loadChildren: () => import('./invitation-link/invitation-link.module').then( m => m.InvitationLinkPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'bill-new',
    loadChildren: () => import('./bill-new/bill-new.module').then( m => m.BillNewPageModule)
  },
  {
    path: 'bank-recon',
    loadChildren: () => import('./bank-recon/bank-recon.module').then( m => m.BankReconPageModule)
  },
  {
    path: 'bank-account',
    loadChildren: () => import('./bank-account/bank-account.module').then( m => m.BankAccountPageModule)
  },
  {
    path: 'add-bank-account',
    loadChildren: () => import('./add-bank-account/add-bank-account.module').then( m => m.AddBankAccountPageModule)
  },
  {
    path: 'start-login2',
    loadChildren: () => import('./start-login2/start-login2.module').then( m => m.StartLogin2PageModule)
  },
  {
    path: 'mfa',
    loadChildren: () => import('./mfa/mfa.module').then( m => m.MfaPageModule)
  },
  {
    path: 'trial-balance',
    loadChildren: () => import('./trial-balance/trial-balance.module').then( m => m.TrialBalancePageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }), NgSelectModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
