/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
export const environment = {
  production: false
};

export const baseUrl = 'https://us-central1-cwceng-entitymanagement.cloudfunctions.net/widgets3'
export const sgUrl = 'https://us-central1-cwceng-entitymanagement.cloudfunctions.net/sgWidget'
export const taxUrl = 'https://us-central1-cwceng-entitymanagement.cloudfunctions.net/taxWidget'
//export const accountingUrl = 'https://us-central1-cwceng-entitymanagement.cloudfunctions.net/altomateAccounting'
// export const baseUrl = 'http://localhost:5001/cwceng-entitymanagement/us-central1/widgets3'
export const xeroLink = 'https://app.altomate.io'
export const CLOUD_FUNCTION_WIDGETS_URL = "https://us-central1-cwceng-entitymanagement.cloudfunctions.net/widgets2"
export const TAX_SOURCE_ID = 13
export const XERO_AC_REDIRECT_URI = "https://app.altomate.io/xero-access"
export const STAGE = "prod"
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAnEFcHEaiJA3HdjkmW7UlPQzyo-M8sw-s",
  authDomain: "cwceng-entitymanagement.firebaseapp.com",
  databaseURL: "https://cwceng-entitymanagement-default-rtdb.firebaseio.com",
  projectId: "cwceng-entitymanagement",
  storageBucket: "cwceng-entitymanagement.appspot.com",
  messagingSenderId: "204373321923",
  appId: "1:204373321923:web:7c910e21dc9f3ba9bc9a76"
};
